import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";
import PrintOrder from "./components/printOrder";
import FormData from "./components/formData";
import "./App.css";
import "./styling/print.css";

export default function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<FormData />}>
          <Route index element={<Home />}></Route>
          <Route path="/review" element={<PrintOrder />}></Route>
        </Route>
      </Routes>
    </Router>
  );
}

