import { getExtCost } from "../helpers/pricing.helpers";
import "./pricing.css";

export default function Pricing({
  updateSubTotals,
  updateCubesTotal,
  itemPrice,
  cubeCount,
  quantity,
  updateQuantityState,
  isOptional
}) {
  console.log(quantity);

  //Derived State
  // Item price remains, but extCost is rendered again

  const extCost = getExtCost(quantity || 0, itemPrice);
  const extCubes = cubeCount * (quantity || 0);


  return (
    <div className="pricing-container" 
    onWheel={(event) => {
      if (event.target.type === "number") {
        event.target.blur();
      }
    }}
    >
      <div className="pricing-section">
        <label htmlFor="quantity">Quantity</label>
        <input
          type="number"
          name="quantity"
          id="quantity"
          step="1"
          min="1"
          value={quantity || ""}
          required={!isOptional}
          onChange={(e) => {
            updateQuantityState(Number(e.target.value));
          }}
        />
      </div>
      <div className="pricing-section">
        <label htmlFor="ea-cost">Cost (ea)</label>
        <input
          type="text"
          name="ea-cost"
          id="ea-cost"
          readOnly
          value={Number(itemPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits:2})}
        />
      </div>
      <div className="pricing-section">
        <label htmlFor="ext-cost">Extended Cost</label>
        <input
          type="text"
          name="ext-cost"
          id="ext-cost"
          value={Number(extCost).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits:2})}
          readOnly
        />
      </div>
      <div className="pricing-section">
        <label htmlFor="cubes">Cube Subtotal (ft³)</label>
        <input
          type="text"
          name="cubes"
          id="cubes"
          value={Number(extCubes).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}
          readOnly
        />
      </div>
      <button
        className="confirm-btn"
        type="button"
        onClick={() => {
          // updateModelQuoteState("quantityOrdered", e.target.value)
          updateSubTotals(extCost);
          updateCubesTotal(extCubes);
        }}
      >
        Confirm
      </button>
    </div>
  );
}
