import React from "react";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { generateID } from "../helpers/generateID.helpers";

function yearMonthDay(dateString){
  const parts = dateString.split("/");
  const correctedDate = `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(2, "0")}`;
  return correctedDate;
}

const todaysDate = yearMonthDay(new Date().toLocaleDateString());

export default function FormData() {
  const [countM, setCountM] = useState(1);
  const [countB, setCountB] = useState(1);
  const [subTotalsM, setSubTotalsM] = useState([]);
  const [subTotalsB, setSubTotalsB] = useState([]);
  const [quantityM, setQuantityM] = useState([]);
  const [quantityB, setQuantityB] = useState([]);
  const [modelCubeArr, setModelCubeArr] = useState([]);
  const [baseCubeArr, setBaseCubeArr] = useState([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [customerData, setCustomerData] = useState({
    order__date: todaysDate,
    co__name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    contact: "",
    phone: "",
    email: "",
    account__rep: "",
    ship__date: "",
    order__notes: "",
  });
  //BasePicker State
  const [baseData, setBaseData] = useState([[""]]);
  const [base, setBase] = useState([""]);
  //ModelPicker State
  const [modelData, setModelData] = useState([[""]]);
  const [modelPickerState, setModelPickerState] = useState({
    modelNumber: [""],
    ventType: [""],
    frameFinish: [""],
    fabricBrand: [""],
    fabricColor: [""],
    uniqueID: [generateID()]
  });
  // Order Quantity State
  const [modelQuantity, setModelQuantity] = useState([]);
  const [baseQuantity, setBaseQuantity] = useState([]);
  //Used in ModelPicker
  const [list, setList] = useState([]);
  const [colorNums, setColorNums] = useState({});
  const [itemData, setItemData] = useState({});
  //Used in BasePicker
  const [baseList, setBaseList] = useState([]);

  async function getBase() {
    try {
      let response = await fetch(
        "https://tempo-order-writer.vercel.app/api/bases/"
      );
      let result = await response.json();
      setBaseList(result);
    } catch (err) {
      alert(err);
    }
  }

  async function getModel() {
    try {
      let response = await fetch(
        "https://tempo-order-writer.vercel.app/api/models",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let result = await response.json();
      console.log("the fetch finished");
      setList(result);

      //Begin extracting data and store it for more convenient (fast) access later
      const fabricData = {};
      const itemData = {};

      for (let i = 0; i < result.length; i++) {
        //Fabric Data
        if (!fabricData[result[i].fabric_brand]) {
          fabricData[result[i].fabric_brand] = {};
        }
        fabricData[result[i].fabric_brand][result[i].fabric_color] =
          result[i].fabric_number;

        //Item Data -> use for bases
        itemData[result[i].item_number] = {
          item_price: result[i].item_price,
          item_cube: result[i].item_cube,
        };
      }
      setItemData(itemData);
      setColorNums(fabricData);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getModel();
    getBase();
  }, []);

  return (
    <div>
      <Outlet
        context={{
          countM: [countM, setCountM],
          countB: [countB, setCountB],
          subTotalsM: [subTotalsM, setSubTotalsM],
          subTotalsB: [subTotalsB, setSubTotalsB],
          quantityM: [quantityM, setQuantityM],
          quantityB: [quantityB, setQuantityB],
          modelCubeArr: [modelCubeArr, setModelCubeArr],
          baseCubeArr: [baseCubeArr, setBaseCubeArr],
          modelData: [modelData, setModelData],
          baseData: [baseData, setBaseData],
          base: [base, setBase],
          list: [list, setList],
          colorNums: [colorNums, setColorNums],
          baseList: [baseList, setBaseList],
          itemData: [itemData, setItemData],
          customerData: [customerData, setCustomerData],
          orderNumber: [orderNumber, setOrderNumber],
          modelPickerState: [modelPickerState, setModelPickerState],
          modelQuantity: [modelQuantity, setModelQuantity],
          baseQuantity: [baseQuantity, setBaseQuantity],
        }}
      />
    </div>
  );
}
