import { useState, useEffect } from "react";
import { getListOf } from "../helpers/model.helpers";
import { useOutletContext } from "react-router-dom";
import {
  populateBaseDesc,
  populateBaseWeight,
  populateBaseColor,
  populateBasePrice,
  populateBaseCube,
} from "../helpers/populate.helpers";
import Pricing from "./pricing";
import "./basePicker.css";

export default function BasePicker({
  updateSubTotals,
  updateQuantities,
  updateCubesTotal,
  updateBaseData,
  base,
  countB,
  updateBase,
  baseQuantity,
  updateBaseQuantityState,
  removeEntireBase
}) {

  const {baseList: [baseList]} = useOutletContext();

  let bases = getListOf(baseList, "item_number");
  const description = populateBaseDesc(base, baseList) || "";
  const baseWeight = populateBaseWeight(base, baseList) || "";
  const baseColor = populateBaseColor(base, baseList) || "";
  const itemPrice = populateBasePrice(base, baseList) || "";
  const itemCubes = populateBaseCube(base, baseList) || "";

  return (
    <div className="base-table">
      <div className="base-section">
        <h4>Base Selection</h4>
        <div className="base-picker-section">
          <div>
            <button 
              className="model-remove-btn"
              title="Remove this base selection."
              type="button"
              disabled={countB <= 1}
              onClick={removeEntireBase}
            >&times;</button>
            <label htmlFor="bases">Base Number</label>
            <div className="dropdown detail">
              <select
                name="bases"
                id="bases"
                value={base}
                onChange={(e) => {
                  updateBase(e.target.value);
                  updateBaseData(
                    e.target.value,
                    populateBaseDesc(e.target.value, baseList),
                    populateBaseWeight(e.target.value, baseList),
                    populateBaseColor(e.target.value, baseList),
                    populateBaseCube(e.target.value, baseList)
                  );
                }}
              >
                <option value="">Select One</option>
                {bases.map((base, idx) => {
                  return (
                    <option key={base + idx} value={base}>
                      {base}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="base-features">
            <label htmlFor="base-description">Base Description</label>
            <div className="detail">
              <p className="base-description" id="base-description">
                {description}
              </p>
            </div>
          </div>
          <div className="base-features">
            <label htmlFor="base-weight">Base Weight</label>
            <div className="detail">
              <p className="base-description" id="base-weight">
                {baseWeight}
              </p>
            </div>
          </div>
          <div className="base-features">
            <label htmlFor="base-weight">Base Color</label>
            <div className="detail">
              <p className="base-description" id="base-color">
                {baseColor}
              </p>
            </div>
          </div>
          {/* get cube data for bases */}
        </div>
      </div>
      <Pricing
        updateSubTotals={updateSubTotals}
        updateQuantities={updateQuantities}
        updateCubesTotal={updateCubesTotal}
        itemPrice={itemPrice}
        cubeCount={itemCubes}
        quantity={baseQuantity}
        updateQuantityState={updateBaseQuantityState}
        isOptional={true}
      />
    </div>
  );
}
