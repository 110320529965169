import React, { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import QuoteSheet from "./quoteSheet";
import ModelPicker from "./modelPicker";
import BasePicker from "./basePicker";
import { generateID } from "../helpers/generateID.helpers";

export default function Home(){

  const {countM : [countM, setCountM]} = useOutletContext();
  const {countB : [countB, setCountB]} = useOutletContext();
  const {subTotalsM : [subTotalsM, setSubTotalsM]} = useOutletContext();
  const {subTotalsB : [subTotalsB, setSubTotalsB]} = useOutletContext();
  const {modelCubeArr : [modelCubeArr, setModelCubeArr]} = useOutletContext();
  const {baseCubeArr : [baseCubeArr, setBaseCubeArr]} = useOutletContext();
  //BasePicker Context
  const {baseData : [baseData, setBaseData]} = useOutletContext();
  const {base: [base, setBase]} = useOutletContext();
  //Review Sheet Context
  const {customerData : [customerData, setCustomerData]} = useOutletContext();
  const {orderNumber : [orderNumber, setOrderNumber]} = useOutletContext();
  //ModelPicker Context
  const {modelPickerState : [modelPickerState, setModelPickerState]} = useOutletContext();
  const {modelData : [modelData, setModelData]} = useOutletContext();
  // ModelQuantity Context 
  const {modelQuantity : [modelQuantity, setModelQuantity]} = useOutletContext();
  const {baseQuantity : [baseQuantity, setBaseQuantity]} = useOutletContext();

  function modelArrTotal(boxIdx, boxSubTotal) {
    const copyArrTotalsM = Array.from(subTotalsM);
    copyArrTotalsM[boxIdx] = boxSubTotal;
    setSubTotalsM(copyArrTotalsM);
  }

  function getModelData(boxIdx, boxData, modelDescription) {
    const copyArrDataM = Array.from(modelData);
    copyArrDataM[boxIdx] = [boxData, modelDescription];
    setModelData(copyArrDataM);
  }

  function getBaseData(boxIdx, boxData, baseDescription) {
    const copyArrDataB = Array.from(baseData);
    copyArrDataB[boxIdx] = [boxData, baseDescription];
    setBaseData(copyArrDataB);
  }

  function modelCubeTotal(boxIdx, boxCubes) {
    const copyModelCubeArr = Array.from(modelCubeArr);
    copyModelCubeArr[boxIdx] = boxCubes;
    setModelCubeArr(copyModelCubeArr);
  }

  function baseArrTotal(boxIdx, boxSubTotal) {
    const copyArrTotalsB = Array.from(subTotalsB);
    copyArrTotalsB[boxIdx] = boxSubTotal;
    setSubTotalsB(copyArrTotalsB);
  }

  function baseCubeTotal(boxIdx, boxCubes) {
    const copyBaseCubeArr = Array.from(baseCubeArr);
    copyBaseCubeArr[boxIdx] = boxCubes;
    setBaseCubeArr(copyBaseCubeArr);
  }

  function handleCustomerData(e) {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value
    });
  }

  function updateBase(boxIdx, newBase){
    const copyBaseArr = Array.from(base);
    copyBaseArr[boxIdx] = newBase;
    setBase(copyBaseArr);
  }

  function updateModelPickerState(boxIdx, objKey, newState){
    const copyModelPickerStateArr = Array.from(modelPickerState[objKey]);
    copyModelPickerStateArr[boxIdx] = newState;

    setModelPickerState({
      ...modelPickerState,
      [objKey]: copyModelPickerStateArr
    });
  }

  function removeEntireModel(idx){
    //Decrease countM by 1
    setCountM(countM - 1);
    //Splice out modelData
    const copyModelData = Array.from(modelData);
    copyModelData.splice(idx, 1);
    setModelData(copyModelData);
    //Splice out modelQuantity
    const copyModelQuantity = Array.from(modelQuantity);
    copyModelQuantity.splice(idx, 1);
    setModelQuantity(copyModelQuantity);
    //Splice out each of the modelPickerState subarrays
    setModelPickerState({
      modelNumber: modelPickerState["modelNumber"].slice(0, idx).concat(modelPickerState["modelNumber"].slice(idx+1)),
      ventType: modelPickerState["ventType"].slice(0, idx).concat(modelPickerState["ventType"].slice(idx+1)),
      frameFinish: modelPickerState["frameFinish"].slice(0, idx).concat(modelPickerState["frameFinish"].slice(idx+1)),
      fabricBrand: modelPickerState["fabricBrand"].slice(0, idx).concat(modelPickerState["fabricBrand"].slice(idx+1)),
      fabricColor: modelPickerState["fabricColor"].slice(0, idx).concat(modelPickerState["fabricColor"].slice(idx+1)),
      uniqueID: modelPickerState["uniqueID"].slice(0, idx).concat(modelPickerState["uniqueID"].slice(idx+1)),
    });
    //Splice modelCubeArr
    const copyModelCubeArr = Array.from(modelCubeArr);
    copyModelCubeArr.splice(idx, 1);
    setModelCubeArr(copyModelCubeArr);
    //Splice subtotalsM
    const copySubtotalsM = Array.from(subTotalsM);
    copySubtotalsM.splice(idx, 1);
    setSubTotalsM(copySubtotalsM);

    //Do we need to update quantityM still?
  }

  function removeEntireBase(idx) {
    //Decrease countB
    setCountB(countB - 1);
    //Splice out baseQuantity
    const copyBaseQuantity = Array.from(baseQuantity);
    copyBaseQuantity.splice(idx, 1);
    setBaseQuantity(copyBaseQuantity);
    //Splice out the base
    const copyBase = Array.from(base);
    copyBase.splice(idx, 1);
    setBase(copyBase);
    //Splice out the baseData
    const copyBaseData = Array.from(baseData);
    copyBaseData.splice(idx, 1);
    setBaseData(copyBaseData);
    // Deal with the baseCubeArr AND ALSO THE MODEL CUBE ARR
    const copyBaseArr = Array.from(baseCubeArr);
    copyBaseArr.splice(idx, 1);
    setBaseCubeArr(copyBaseArr);
    // Double check the subtotalsB things
    const copySubtotalsB = Array.from(subTotalsB);
    copySubtotalsB.splice(idx, 1);
    setSubTotalsB(copySubtotalsB);
  }

  function updateModelQuantityState(boxIdx, newState){
    const copyModelQuantityArr = Array.from(modelQuantity);
    copyModelQuantityArr[boxIdx] = newState;
    setModelQuantity(copyModelQuantityArr);
  }

  function updateBaseQuantityState(boxIdx, newState){
    const copyBaseQuantityArr = Array.from(baseQuantity);
    copyBaseQuantityArr[boxIdx] = newState;
    setBaseQuantity(copyBaseQuantityArr);
  }

  const length = 7;
  function createOrderNumber(length) {
    const tempOrderNumber = "OW" + Math.floor(Math.pow(10, length-1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length-1) - 1));
    setOrderNumber(tempOrderNumber);
  }

  const cubeMax = 2350;

  const navigate = useNavigate();
  // need to add cube to review page w/ logic to stop advance if total > 2694

  const handleReviewClick = () => {
    if ((modelData.length < 1 || !isCustomerDataFulfilled)
      // || Object.keys(customerData).length < 11
    ) {
      // change to modal/some other popup rather than alert
      window.alert('Please complete the form before continuing.');
    } else if (modelData.length >= 1 && baseData.length < 1 && isCustomerDataFulfilled) {
      navigate("/review", { state: {
        countM,
        orderNumber,
        customerData, 
        modelData,
        modelQuantity, 
        subTotalsM,
        gTotal,
        cTotal,
        modelCubeArr,
        qTotal
      }});
    } else {
      //Ignore empty (unused) bases
      setCountB(baseData.length);
      // if countB is greater than 1
      // check each object within baseData
        // remove any objects that are bogus
      navigate("/review", { state: {
        countM,
        countB : baseData.length,
        orderNumber,
        customerData, 
        modelData,
        baseData,
        modelQuantity, 
        baseQuantity, 
        subTotalsM, 
        subTotalsB,
        gTotal,
        cTotal,
        modelCubeArr,
        baseCubeArr,
        qTotal
      }});
    }
  }

  useEffect(() => {
    createOrderNumber(length);

    // FOR TESTING ONLY
    // fetch(`https://tempo-order-writer.vercel.app/echo`)
    // .then((res) => res.json())
    // .then(data => {
    //   console.log("TESTING SERVER GET", data);
    // })
    // .catch(console.error);

    // fetch(`https://tempo-order-writer.vercel.app/postTest`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     info: "foo",
    //     data: ["bar", "bar2", "bar3"]
    //   })
    // })
    // .then((res) => res.json())
    // .then(data => {
    //   console.log("TESTING SERVER POST", data);
    // })
    // .catch(console.error);
    // END TESTING

  }, []);

  const requiredCustomerData = [
    "order__date",
    "co__name",
    "address",
    "city",
    "state",
    "zip",
    "contact",
    "phone",
    "email",
    "account__rep",
    "ship__date",
  ];

  //Check whether the required quoteSheet fields are all truthy or not
  const isCustomerDataFulfilled = requiredCustomerData.every((field) => {
    return customerData[field];
  });
  const gTotal = (subTotalsM.reduce((a, b) => a + b, 0) + subTotalsB.reduce((a, b) => a + b, 0));
  const gTotalDisplay = gTotal.toFixed(2);
  // evaluate formula
  const cTotal = (modelCubeArr.reduce((a, b) => a + b, 0) + baseCubeArr.reduce((a, b) => a + b, 0));
  const cTotalDisplay = Number(cTotal).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2});
  const qTotal = (modelQuantity.reduce((a, b) => a + b, 0) + baseQuantity.reduce((a, b) => a + b, 0));

  console.log(modelQuantity);
  console.log(modelPickerState);

  return (
    <form className="App" onSubmit={(event) => {
      event.preventDefault();
      handleReviewClick();
    }}>
      <QuoteSheet handleFormChange={(e) => handleCustomerData(e)} customer={customerData} order={orderNumber}/>
      {Array(countM).fill(0).map((_, idx) => {
        return <ModelPicker
          key={modelPickerState.uniqueID[idx]}
          idx={idx}
          updateSubTotals={(boxSubTotal) => {modelArrTotal(idx, boxSubTotal)}}
          updateModelData={(boxData, chosenModelDesc) => {getModelData(idx, boxData, chosenModelDesc)}}
          updateCubesTotal={(boxCubeCount) => {modelCubeTotal(idx, boxCubeCount)}}
          modelData={modelData[idx]}
          modelPickerState={modelPickerState}
          updateModelPickerState={(objKey, newState) => {updateModelPickerState(idx, objKey, newState)}}
          // 
          modelQuantity={modelQuantity[idx]}
          countM={countM}
          updateModelQuantityState={(newState) => {updateModelQuantityState(idx, newState)}}
          removeEntireModel={() => {removeEntireModel(idx)}}
        />
      })}
      <button type="button" onClick={() => {
        setCountM((prevCount) => prevCount + 1);
        updateModelPickerState(countM, "uniqueID", generateID())
      }}>Add New</button>
      {Array(countB).fill(0).map((_, idx) => {
        return <BasePicker
          key={idx}
          updateSubTotals={(boxSubTotal) => {baseArrTotal(idx, boxSubTotal)}}
          // check whether new data is included in baseData array
          updateBaseData={(boxData, chosenBaseDesc) => {getBaseData(idx, boxData, chosenBaseDesc)}}
          updateCubesTotal={(boxCubeCount) => {baseCubeTotal(idx, boxCubeCount)}}
          baseData={baseData[idx]}
          base={base[idx]}
          updateBase={(newBase) => {updateBase(idx, newBase)}}
          // 
          countB={countB}
          baseQuantity={baseQuantity[idx]}
          updateBaseQuantityState={(newState) => {updateBaseQuantityState(idx, newState)}}
          removeEntireBase={() => {removeEntireBase(idx)}}
        />
      })}
      <button type="button" onClick={() => {
        setCountB((prevCount) => prevCount + 1)
      }}>Add New</button>
      <div className="cost-container">
        {/* layout needs more work */}
        <div className="total-cost">
          <label htmlFor="total">Total Price:</label>
          <input 
            type="text"
            name="total"
            id="total"
            value={Number(gTotalDisplay).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits:2})}
            readOnly
            />
            {/* create custom error message for when cubes exceed max */}
            {/* debug 'cannot parse' error for number with commas - hide and use p, or self-validate*/}
            <div className="total-cube">
          <label htmlFor="">Total Cubes:</label>
          <input
            type="number" 
            name="test"
            max={cubeMax} 
            min={1} 
            step="0.01"
            value={cTotal.toFixed(2)} 
            className="readOnly-validated"
            />
          <p className="cube-calc" name="test" style={{display: "inline"}}> /{cubeMax} ft³</p>
            </div>
        </div>
      </div>
      <div className="submit-order">
        <button type="submit">Finish and Review</button>
      </div>
      <div className="contact">
        <p>Please reach out with any questions, comments, or concerns. Thank you.</p>
        <div className="contact-els">
        <button onClick={() => window.location = 'mailto:info@tempoleisure.com'} className="contact-btn">Email Us</button>
        <p>or get in touch <a href="https://tempopatio.com/contact" target="_blank" rel="noreferrer" className="external">on the web</a>.</p>
        </div>
      </div>
      <div className="attributions">
        <p>© Tempo Patio 2023 </p>
        <p>Version 1.0</p>
      </div>
    </form>
  );
}

