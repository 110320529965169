export function populateDesc(model, list) {
    const description = list.find((result) => {
        return result.model === model;
    })?.description;
    return description;
}

export function populateAbv(frame_color, list) {
    const frame_initials = list.find((result) => {
        return result.frame_color === frame_color;
    })?.frame_initials;
    return frame_initials;
}

export function populateBaseDesc(item_number, list) {
    const description = list.find((result) => {
        return result.item_number === item_number
    })?.description;
    return description;
}

export function populateBaseWeight(item_number, list) {
    const weight = list.find((result) => {
        return result.item_number === item_number
    })?.weight;
    return weight;
}

export function populateBaseColor(item_number, list) {
    const color = list.find((result) => {
        return result.item_number === item_number
    })?.color;
    return color;
}

export function populateBasePrice(item_number, list) {
    const price = list.find((result) => {
        return result.item_number === item_number
    })?.item_price;
    return price;
}

export function populateBaseCube(item_number, list) {
    const cube = list.find((result) => {
        return result.item_number === item_number
    })?.item_cube;
    return cube;
}