import { forwardRef, useState } from "react";
import { useLocation } from "react-router-dom";
import logoBrown from "../assets/tp-brown.png";
import "./orderReview.css";

const OrderReview = forwardRef(function OrderReview({ handleOrderPrint }, ref) {
  const { state } = useLocation();
  console.log(state);
  const initialOrderTotal = state.gTotal;
  const initialCubeTotal = state.cTotal;
  const initialQuantities = state.qTotal;
  const [data, setData] = useState(state);
  // quantity and cube displays similar to below, w/ delete functionality
  const [orderTotal, setOrderTotal] = useState(initialOrderTotal);
  // review formula for totaling/updating quantities
  const [cubeTotal, setCubeTotal] = useState(initialCubeTotal);

  //Swap date formats
  function YMDtoMDY(prevDate) {
    const parts = prevDate.split("-");
    return `${parts[1]}-${parts[2]}-${parts[0]}`;
  }

  const order_date = YMDtoMDY(state.customerData.order__date);
  const ship_date = YMDtoMDY(state.customerData.ship__date);

  const handleDelete = (index, e, rowType) => {
    if (rowType === "modelData") {
      console.log(index);
      setData({
        ...data,
        modelData: data.modelData.filter((idx) => {
          return idx !== index;
        }),
        modelQuantity: data.modelQuantity.filter((idx) => {
          return idx !== index;
        }),
        subTotalsM: data.subTotalsM.filter((idx) => {
          return idx !== index;
        }),
        countM: data.countM - 1,
      });
      setOrderTotal(orderTotal - data.subTotalsM[index]);
      setCubeTotal(cubeTotal - data.modelCubeArr[index]);
      // need to add quantity update on delete
    } else if (rowType === "baseData") {
      setData({
        ...data,
        baseData: data.baseData.filter((idx) => {
          return idx !== index;
        }),
        baseQuantity: data.baseQuantity.filter((idx) => {
          return idx !== index;
        }),
        subTotalsB: data.subTotalsB.filter((idx) => {
          return idx !== index;
        }),
        countB: data.countB - 1,
      });
      setOrderTotal(orderTotal - data.subTotalsB[index]);
      setCubeTotal(cubeTotal - data.baseCubeArr[index]);
    }
  };

  const numberModels = data.countM;
  const modelTableRowArr = [];
  for (let i = 0; i < numberModels; i++) {
    const newModelObject = {
      modelRowID: i,
      modelNumber: data.modelData[i][0],
      modelDescription: data.modelData[i][1],
      quantityOrdered: data.modelQuantity[i],
      orderCube: data.modelCubeArr[i],
      modelSubTotal: data.subTotalsM[i],
    };
    modelTableRowArr.push(newModelObject);
  }
  console.log(modelTableRowArr);

  const numberBases = data.countB;
  const baseTableRowArr = [];
  for (let i = 0; i < numberBases; i++) {
    const newBaseObject = {
      baseRowID: i,
      baseNumber: data.baseData[i][0],
      baseDescription: data.baseData[i][1],
      quantityOrdered: data.baseQuantity[i],
      orderCube: data.baseCubeArr[i],
      baseSubTotal: data.subTotalsB[i],
    };
    baseTableRowArr.push(newBaseObject);
  }
  console.log(baseTableRowArr);

  const fullAddress = [
    state.customerData.address + ", ",
    state.customerData.city + ", ",
    state.customerData.state + " ",
    state.customerData.zip,
  ];

  // work on getting correct date display on page
  // function yearMonthDay(dateString){
  //   const parts = dateString.split("/");
  //   const correctedDate = `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(2, "0")}`;
  //   return correctedDate;
  // }

  return (
    <div className="overview" ref={ref}>
      <img
        src={logoBrown}
        className="logo-brown"
        alt="brown Tempo Patio logo"
      />
      <div id="fiuo">
        <p>
          <strong>For Internal Use Only:</strong>
        </p>
        <label htmlFor="approval__date">Approval Date</label>
        <input name="approval__date" id="approval__date" />
      </div>
      <p>
        <strong>Order Number:</strong>{" "}
        <strong id="order-number">{state.orderNumber}</strong>
      </p>
      <div className="order-meta">
        <p>
          <strong>Order Date:</strong> {order_date}
        </p>
        <p>
          <strong>Company/Customer Name:</strong> {state.customerData.co__name}
        </p>
        <p>
          <strong>Company/Customer Address:</strong> {fullAddress}
        </p>
        <p>
          <strong>Contact Name:</strong> {state.customerData.contact}
        </p>
        <p>
          <strong>Contact Phone Number:</strong> {state.customerData.phone}
        </p>
        <p>
          <strong>Contact Email:</strong> {state.customerData.email}
        </p>
        <p>
          <strong>Requested Ship Date:</strong> {ship_date}
        </p>
        <p id="order-notes">
          <strong>Order Notes:</strong> {state.customerData.order__notes}
        </p>
      </div>
      <table className="order-data">
        <thead>
          <tr className="data-header">
            <th>Model Number</th>
            <th>Model / Base Description</th>
            <th>Quantity</th>
            <th>Extended Cost</th>
            <th>Cube Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {modelTableRowArr.map((lineItem, idx) => {
            return (
              <tr key={idx}>
                <td>{lineItem.modelNumber}</td>
                <td>{lineItem.modelDescription}</td>
                <td>{lineItem.quantityOrdered}</td>
                <td>{Number(lineItem.modelSubTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                <td>{lineItem.orderCube.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                <td>
                  <button
                    onClick={(e) => {
                      handleDelete(idx, e, "modelData");
                    }}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
          {baseTableRowArr.map((lineItem, idx) => {
            if (lineItem.baseNumber) {
            return ( 
              <tr key={idx}>
                <td>{lineItem.baseNumber}</td>
                <td>{lineItem.baseDescription}</td>
                <td>{lineItem.quantityOrdered}</td>
                <td>{Number(lineItem.baseSubTotal)?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                <td>{lineItem.orderCube?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
                <td>
                  <button
                    onClick={(e) => {
                      handleDelete(idx, e, "baseData");
                    }}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          } else {
            return;
          }
          })}
          <tr className="order-totals-row">
            <td className="total-field"></td>
            <td className="total-field">Total:</td>
            <td className="total-field">{initialQuantities}</td>
            <td className="total-field">{Number(orderTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
            <td className="total-field">{cubeTotal.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2})}</td>
          </tr>
        </tbody>
      </table>
      <button onClick={handleOrderPrint} className="print-btn">
        Print Order
      </button>
    </div>
  );
});

export default OrderReview;
