import React, { useState, useEffect, useMemo } from "react";
import { getListOf } from "../helpers/model.helpers";
import { populateAbv, populateDesc } from "../helpers/populate.helpers";
import { validate } from "../helpers/validation.helpers";
import { useOutletContext } from "react-router-dom";
// import { ventAbbr } from "../helpers/vent.helpers";
import Pricing from "./pricing";
import "./modelPicker.css";

// reevaluate need for populate helpers

export default function ModelPicker({
  updateSubTotals,
  updateModelData,
  updateCubesTotal,
  updateModelPickerState,
  modelPickerState,
  idx,
  countM,
  modelQuantity,
  updateModelQuantityState,
  removeEntireModel
}) {
  const [itemPrice, setItemPrice] = useState(0);
  const [itemCubes, setItemCubes] = useState(0);

  //const {countM : [countM, setCountM]} = useOutletContext();
  const {list: [list]} = useOutletContext();
  const {itemData: [itemData]} = useOutletContext();
  const {colorNums: [colorNums]} = useOutletContext();

  /*
    useMemo isn't even the best solution to this. 
    Really, all of these `getListOf` calls should only happen once, after the fetch comes back.
    They don't need to run on every render of the component. 
    But useMemo essentially fixes the problem without having to rearrange any code
  */
  let models = useMemo(() => getListOf(list, "model"), [list]);
  let vents = useMemo(() => getListOf(list, "vent_type"), [list]);
  let finishes = useMemo(() => getListOf(list, "frame_color"), [list]);
  let fabricBrands = useMemo(() => getListOf(list, "fabric_brand"), [list]);
  let valids = useMemo(() => getListOf(list, "item_number"), [list]);
  let frameAbv = populateAbv(modelPickerState.frameFinish[idx], list) || "";
  let modelDesc = populateDesc(modelPickerState.modelNumber[idx], list) || "";

  let fabricNumber = "";
  if (
    colorNums &&
    Object.keys(colorNums).length > 1 &&
    modelPickerState.fabricBrand[idx] &&
    modelPickerState.fabricColor[idx]
  ) {
    fabricNumber =
      colorNums[modelPickerState.fabricBrand[idx]][
        modelPickerState.fabricColor[idx]
      ] || "";
  }
  let selectColors = [];
  if (
    colorNums &&
    Object.keys(colorNums).length > 1 &&
    modelPickerState.fabricBrand[idx]
  ) {
    selectColors = Object.keys(colorNums[modelPickerState.fabricBrand[idx]]);
  }

  let itemNumber = [
    modelPickerState.modelNumber[idx],
    modelPickerState.ventType[idx],
    frameAbv,
    fabricNumber,
  ].join("-");
  let valid = validate(valids, itemNumber);

  // use complete itemNumber and validModelPush (valid) to get price
  const validModelPush = (tempItemNumber) => {
    if (validate(valids, tempItemNumber)) {
      updateModelData(tempItemNumber, modelDesc);
      //Use itemData to get the correct itemPrice and then setItemPrice
      //Examine whether formData state needs to exist here
      setItemPrice(itemData[tempItemNumber].item_price);
      setItemCubes(itemData[tempItemNumber].item_cube);
    } else {
    }
  };

  useEffect(() => {
    validModelPush(itemNumber);
  }, [itemNumber]);

  // need to format all number outputs
  // update readOnly inputs to vibe less interaction

  console.log(modelQuantity);

  return (
    <div className="model-table">
      <div className="model-list">
        <button className="model-remove-btn"
          title="Removes this model selection."
          type="button"
          disabled={countM <= 1}
          onClick={removeEntireModel}
        >&times;</button>
        <div className="picker-section">
          <h4>Model Selection</h4>
          <label htmlFor="model-number">Model Number</label>
          <div className="dropdown detail">
            <select
              name="model-number"
              id="model-number"
              required
              value={modelPickerState.modelNumber[idx]}
              onChange={(e) => {
                updateModelPickerState("modelNumber", e.target.value);
              }}
            >
              <option value="">Select One</option>
              {models.map((model, idx) => {
                return (
                  <option key={model + idx} value={model}>
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="description">Item Description</label>
          <div className="detail">
            <p className="model-description">{modelDesc}</p>
          </div>
          <label htmlFor="vent-type">Vent Type</label>
          <div className="dropdown detail">
            <select
              name="vent-type"
              id="vent-type"
              required
              value={modelPickerState.ventType[idx]}
              onChange={(e) => {
                updateModelPickerState("ventType", e.target.value);
              }}
            >
              <option value="">Select One</option>
              {vents.map((vent, idx) => {
                return (
                  <option key={vent + idx} value={vent}>
                    {vent}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="picker-section">
          <h4>Frame Selection</h4>
          <label htmlFor="frame-finish">Frame Finish</label>
          <div className="dropdown detail">
            <select
              name="frame-finish"
              id="frame-finish"
              value={modelPickerState.frameFinish[idx]}
              required
              onChange={(e) => {
                updateModelPickerState("frameFinish", e.target.value);
              }}
            >
              <option value="">Select One</option>
              {finishes.map((finish, idx) => {
                return (
                  <option key={finish + idx} value={finish}>
                    {finish}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="frame-abv">Frame Abv.</label>
          <div className="detail">
            <input
              type="text"
              name="frame-abv"
              id="frame-abv"
              value={frameAbv}
              readOnly
            />
          </div>
        </div>
        <div className="picker-section fabric">
          <h4>Fabric Selection</h4>
          <label htmlFor="fabric-brand">Fabric Brand</label>
          <div className="dropdown detail">
            <select
              name="fabric-brand"
              id="fabric-brand"
              required
              value={modelPickerState.fabricBrand[idx]}
              onChange={(e) => {
                updateModelPickerState("fabricBrand", e.target.value);
                if (!e.target.value) {
                  updateModelPickerState("fabricColor", "");
                }
              }}
            >
              <option value="">Select One</option>
              {fabricBrands.map((fabricBrand, idx) => {
                return (
                  <option key={fabricBrand + idx} value={fabricBrand}>
                    {fabricBrand}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="fabric-brand-color">Fabric Color</label>
          <div className="dropdown detail">
            <select
              name="fabric-brand-color"
              id="fabric-brand-color"
              required
              disabled={modelPickerState.fabricBrand[idx] === ""}
              value={modelPickerState.fabricColor[idx]}
              onChange={(e) => {
                updateModelPickerState("fabricColor", e.target.value);
              }}
            >
              <option value="">Select One</option>
              {selectColors.map((color, idx) => {
                return (
                  <option key={color + idx} value={color}>
                    {color}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="fabric-number">Fabric Number</label>
          <div className="detail">
            <input
              type="text"
              name="fabric-number"
              id="fabric-number"
              value={fabricNumber}
              readOnly
            />
          </div>
        </div>
        <div className="model-validation">
          <h4>Item Validation</h4>
          <label htmlFor="item-number">Item Number</label>
          <input
            type="text"
            name="item-number"
            id="item-number"
            value={itemNumber}
            readOnly
          />
          <label htmlFor="model-valid">Valid Model</label>
          <input
            type="text"
            name="model-valid"
            id="model-valid"
            value={valid ? "Valid" : "Invalid"}
            readOnly
          />
          <p id="validity-note">
            Note: Model will show as <strong>Invalid</strong> until all
            selections are made. If <strong>Invalid</strong> is still shown,
            please adjust your selections. Cost will not display unless the
            selection is valid. Once a valid selection is made, please{" "}
            <strong>Confirm</strong> your selection using the button.
          </p>
        </div>
      </div>
      <Pricing
        updateSubTotals={updateSubTotals}
        updateCubesTotal={updateCubesTotal}
        itemPrice={itemPrice}
        cubeCount={itemCubes}
        idx={idx}
        quantity={modelQuantity}
        updateQuantityState={updateModelQuantityState}
        isOptional={false}
      />
    </div>
  );
}
