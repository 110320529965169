import "./quoteSheet.css";
import logoBrown from "../assets/tp-brown.png";

const phoneRegex = /^\(?([0-9]{0,3})\)?[-. ]?([0-9]{0,3})[-. ]?([0-9]{0,4})$/;
const handlePhoneInput = (value) => {
  return (value.replace(phoneRegex, '($1) $2-$3'));
}


export default function QuoteSheet({handleFormChange, order, customer}) {

  return (
    <div className="sheet-container">
      <img src={logoBrown} className="logo-brown" alt="brown Tempo Patio logo" />
      <div className="intro">
      <h1>Tempo Patio Purchase Order</h1>
      <p>Thank you for your interest in Tempo Patio. To use the form, please follow Steps 1-3:</p>
      <ol>
        <li>Fill in your company, contact, and shipping data. Fields marked with "•" are required.</li>
        <li>Select the desired model and base parts from the dropdown menus.</li>
        <li>Download the Purchase Order to PDF and email to Robin Smith <strong>[rsmith@tempoleisure.com]</strong>.</li>
      </ol>
      </div>
      <div className="form-meta-info">
        <div className="order-number-container">
          <p style={{
            margin: "0"
          }}>Order Number: <strong>{order}</strong></p>
        </div>
        <div className="form-field col">
          <label htmlFor="order__date">Date •</label>
          <input type="date" name="order__date" id="order__date" value={customer["order__date"]} onChange={handleFormChange} required/>
          <label htmlFor="co__name">Company/Customer Name •</label>
          <input type="text" name="co__name" id="co__name" autoCapitalize="words" value={customer["co__name"]} onChange={handleFormChange} required/>
          <label htmlFor="address">Address •</label>
          <input type="text" name="address" id="address" autoComplete="street-address" value={customer["address"]} onChange={handleFormChange} required/>
          <label htmlFor="city">City •</label>
          <input type="text" name="city" id="city" value={customer["city"]} onChange={handleFormChange} required/>
          <label htmlFor="state">State •</label>
          <input type="text" name="state" id="state" value={customer["state"]} onChange={handleFormChange} required/>
          <label htmlFor="zip">ZIP •</label>
          <input type="text" name="zip" id="zip" value={customer["zip"]} onChange={handleFormChange} required/>
          <label htmlFor="contact">Contact Name •</label>
          <input type="text" name="contact" id="contact" value={customer["contact"]} onChange={handleFormChange} required/>
          <label htmlFor="phone">Phone •</label>
          <input type="tel" name="phone" id="phone" autoComplete="tel-national" value={customer["phone"]} onChange={handleFormChange} required/>
          <label htmlFor="email">Email •</label>
          <input type="email" name="email" id="email" autoComplete="email" value={customer["email"]} onChange={handleFormChange} required/>
        </div>
        <div className="form-field col">
          <label htmlFor="account__rep">Account Rep. •</label>
          <input type="text" name="account__rep" id="account__rep" value={customer["account__rep"]} onChange={handleFormChange} autoCapitalize="words" required/>
          <label htmlFor="ship__date">Requested Ship Date •</label>
          <input type="date" name="ship__date" id="ship__date" value={customer["ship__date"]} onChange={handleFormChange} required/>
        </div>
        <div className="form-field col">
          <label htmlFor="order__notes">Notes</label>
          <textarea type="text" name="order__notes" id="order__notes" value={customer["order__notes"]} onChange={handleFormChange}></textarea>
        </div>
      </div>
    </div>
  );
}

